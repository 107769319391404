import { Asset, Collection, User, CollectionFavorites } from "./types";
import { doDelete, doGet, doPost } from "./api_helper";
import { Filter } from "containers/Admin/PageCollectionAdminWip";

export const baseIpfsUrl = process.env.ENV === "development" ? "https://ipfs.blockfrost.dev/ipfs/" : "/ipfs/"

export interface CollectionFilter {
  metadata: { [key: string]: Filter }
  price?: [number, number]
}

const transformAssetStructure = (assets: any[]): Asset[] => {
  return assets.map((d: any) => {
    if (!d.synced) {
      return
    }
    let img = ""
    if (Array.isArray(d.metadata.image)) {
      img = d.metadata.image.join("")
    } else {
      img = "" + (d.metadata.image as string)
    }

    const a: Asset = {
      last_metadata: {
        id: d.metadata.id,
        assetId: d.asset,
        name: d.name,
        image: [img],
        files: [{
          name: d.metadata.files[0].mediaType,
          src: d.metadata.files[0].src
        }],
      },
      project: {
        name: d.project,
      },
      traits: [],
    }
    return a
  }) as Asset[]
}

export const getAssetsFromCollectionFromBackend = async (policyId: string, limit?: number, random?: boolean) => {
  let url = `/api/collection/assets/${policyId}?`;
  if (limit) {
    url += `limit=${limit}`;
  }
  if (random) {
    url += limit ? `&random=true` : `random=true`;
  }

  const res = await doGet<any>(url, {});
  return res.data;
};

export const getCollectionsFromBackend = async (
  page?: number,
  pageSize?: number,
  sortBy?: string,
  priceRange?: [number, number?],
  search?: string,
  tags?: string[],
  curated?: boolean
) => {
  let url = `/api/collection/search?`;

  // Append page and pageSize parameters if provided
  if (page) {
    url += `page=${page}`;
  }
  if (pageSize) {
    url += page ? `&pageSize=${pageSize}` : `pageSize=${pageSize}`;
  }

  // Append sortBy parameter if provided
  if (sortBy) {
    url += `&sortBy=${sortBy}`;
  }

    // Append priceRange parameter if provided
    if (priceRange) {
      const maxPrice = priceRange[1] === Infinity ? 'Infinity' : priceRange[1];
      url += `&priceRange=${priceRange[0]},${maxPrice}`;
    }

  // Append search string parameter if provided
  if (search) {
    url += `&search=${search}`;
  }

  // Append tags parameter if provided
  if (tags && tags.length > 0) {
    url += `&tags=${tags.join(',')}`;
  }

  // Append curated parameter if provided
  if (curated !== undefined) {
    url += `&curated=${curated}`;
  }

  const res = await doGet<any>(url, {});
  return res.data;
};

export const getAssetsFromCollectionFromBackendTest = async (
  policyId: string,
  page?: number,
  pageSize?: number,
  sortBy?: string,
  priceRange?: [number, number?],
  search?: string,
  metadata?: { [key: string]: Filter } // Add metadata parameter
) => {
  let url = `/api/collection/assets/${policyId}?`;

  // Append page and pageSize parameters if provided
  if (page) {
    url += `page=${page}`;
  }
  if (pageSize) {
    url += page ? `&pageSize=${pageSize}` : `pageSize=${pageSize}`;
  }

  // Append sortBy parameter if provided
  if (sortBy) {
    url += `&sortBy=${sortBy}`;
  }

  // Append priceRange parameter if provided
  if (priceRange) {
    url += `&priceRange=${priceRange.join(',')}`;
  }

  // Append search string parameter if provided
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }

  // Append metadata filters to the URL
  console.log('Metadata received:', JSON.stringify(metadata, null, 2));

  // Append metadata filters to the URL
  if (metadata) {
    for (const key in metadata) {
      const filter = metadata[key];
      console.log(`Processing filter for ${key}:`, JSON.stringify(filter, null, 2));
      
      const pathParts = filter.path.split('.').filter(Boolean);
      
      if (filter.numberFilter?.selection) {
        const rangeKey = ['metadata', 'range', ...pathParts].join('.');
        const start = filter.numberFilter.selection.start;
        const end = filter.numberFilter.selection.end;
        
        console.log(`Range values for ${key}:`, start, end);
        
        // Use the string values directly, without parsing to number
        if (start != null && end != null && start !== '' && end !== '') {
          url += `&${rangeKey}=${encodeURIComponent(start.toString())},${encodeURIComponent(end.toString())}`;
        }
      } else if (filter.stringFilter?.selection) {
        const stringKey = ['metadata', 'string', ...pathParts].join('.');
        if (filter.stringFilter.selection.length > 0) {
          url += `&${stringKey}=${encodeURIComponent(filter.stringFilter.selection.join(','))}`;
        }
      }
    }
  }

  console.log('Request URL:', url);

  const res = await doGet<any>(url, {});
  return res.data;
};

export const getCollectionFilterFromBackend = async (policyId: string) => {
  const res = await doGet<any>(`/api/collection/${policyId}/filter`, {})
  return res.data as { [key: string]: Filter }
}

export const getFilteredCollectionFromBackend = async (policyId: string, filters: CollectionFilter) => {
  const res = await doPost<any>(`/api/collection/assets/${policyId}`, filters, {})
  return res.data;
}


// Define a helper function to fetch asset data
export const fetchAssetData = async (policyId: string, assetId: string): Promise<any | null> => {
  try {
    const response = await doGet<any>(`/api/asset/${policyId}/${assetId}`, {});
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching asset data:', error);
    return null;
  }
};

// get collection info from path or id
export const fetchCollectionData = async (searchType: string, value: string): Promise<Collection | null> => {
  try {
    const response = await doGet<any>(`/api/collection/${searchType}/${value}`, {});
    const data = response.data;
    return data as Collection;
  } catch (error) {
    console.error('Error fetching collection data:', error);
    return null;
  }
};

export interface JpgListingsResponse {
  listings: Listing[];
  nextPageCursor: string;
}

export interface Listing {
  asset_id: string;
  confirmed_at: Date;
  display_name: string;
  tx_hash: string;
  listing_id: number;
  listed_at: Date;
  price_lovelace: string;
  listing_type: ListingType;
  bundled_assets?: BundledAsset[];
}

export interface BundledAsset {
  asset_id: string;
  display_name: string;
}

export enum ListingType {
  Bundle = "BUNDLE",
  SingleAsset = "SINGLE_ASSET",
}

export interface ListingResponse { [key: string]: { policy: string, price: number,  listingId: number } }

export const fetchCollectionListings = async (policyId: string): Promise<ListingResponse> => {
  const response = await doGet(`/api/collection/${policyId}/listings`, {})

  return response.data as ListingResponse
}

export const fetchCollectionLikes = async (policyId: string): Promise<{ [key: string]: boolean }> => {
  const response = await doGet(`/api/favorite/${policyId}`, {}, false)
  return response.data as { [key: string]: boolean }
}

export const createLikeOnAsset = async (policyId: string, assetId: string) => {
  await doPost(`/api/favorite/${policyId}/${assetId}`, {}, {}, true)
}

export const deleteLikeOnAsset = async (policyId: string, assetId: string) => {
  await doDelete(`/api/favorite/${policyId}/${assetId}`, {}, true)
}

export const listAllLikedAssets = async () => {
  const res = await doGet(`/api/favorite`, {}, true)
  return res.data as { [key: string]: CollectionFavorites }
}

export const listAllUserLikedAssets = async (userId: string) => {
  const res = await doGet(`/api/user/favorite/${userId}`, {}, false)
  return res.data as { [key: string]: CollectionFavorites }
}

export const fetchFollowsOnUsers = async () => {
  const res = await doGet("/api/user/follow", {}, true)
  return res.data;
}

export const createFollowOnUser = async (userId: string) => {
  await doPost("/api/user/follow", { userId }, {}, true)
}

export const deleteFollowOnUser = async (userId: string) => {
  await doDelete(`/api/user/follow/${userId}`, {}, true)
}

export const fetchAssetListing = async (policyId: string, assetId: string): Promise<ListingResponse | null> => {
  try {
    const response = await doGet(`/api/asset/${policyId}/${assetId}/listings`, {});
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response.data as ListingResponse;
  } catch (error) {
    console.error('Error fetching asset listing data:', error);
    return null;
  }
};

export const fetchUserByName = async (name: string): Promise<User | null> => {
  try {
      const response = await doGet(`/api/user/name/${name.toLowerCase()}`);
      if (response.data.status === 'success') {
          return response.data.data;
      } else {
          console.error('Error fetching user data:', response.data.message);
          return null;
      }
  } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
  }
};

export const fetchUserById = async (id: string): Promise<User | null> => {
  try {
    const response = await doGet(`/api/user/id/${id}`);
    if (response.data.status === 'success') {
      return response.data.data;
    } else {
      console.error('Error fetching user data:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const fetchUsers = async (options?: any): Promise<User[] | null> => {
  try {
    let url = '/api/user';
    if (options) {
      const queryParams: string[] = [];
      if (options.artist) {
        queryParams.push('artist=true');
      }
      if (options.name) {
        queryParams.push('name=true');
      }
      if (queryParams.length > 0) {
        url += '?' + queryParams.join('&');
      }
    }
    const response = await doGet(url);
    if (response.data.status === 'success') {
      return response.data.data;
    } else {
      console.error('Error fetching user data:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};



