import React, { FC } from "react";
import { Link } from "react-router-dom";
import NextPrev from "shared/NextPrev/NextPrev";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import VerifyIcon from "components/VerifyIcon";
import { fetchAssetData, fetchAssetListing, fetchCollectionData } from "helpers/helpers";
import { Collection } from "helpers/types";
import NcImage from "shared/NcImage/NcImage";
import { ListingResponse } from "helpers/helpers";

export interface CardLarge1Props {
  className?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  isShowing?: boolean;
  featuredImgUrl?: string;
  id: string;
  image: string;
  name: string;
  dbname: string;
  src: Array<string> | string;
  policy: string;
}

const CardLarge1: FC<CardLarge1Props> = ({
  className = "",
  isShowing = true,
  onClickNext = () => { },
  onClickPrev = () => { },
  id,
  image,
  name,
  src,
  dbname,
  policy,
}) => {
  const [collectionData, setCollectionData] = React.useState<Collection | null>(null);
  const [showIframe, setShowIframe] = React.useState(true);
  const [rawNFT, setRawNFT] = React.useState("");
  const [assetListingData, setAssetListingData] = React.useState<ListingResponse | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchCollectionData("policy", policy);
        setCollectionData(fetchedData);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [policy]);

  const isIpfs = typeof src == 'string' && src.startsWith("ipfs")

  React.useEffect(() => {
    if (src && src.length > 0) {
      let result;
      if (Array.isArray(src)) {
        const rawNFTArray = src as string[];
        result = rawNFTArray.join('');
      } else {
        result = src;
      }
      // Custom awoken projects rule for pretty display
      if (policy ==
        "95c248e17f0fc35be4d2a7d186a84cdcda5b99d7ad2799ebe98a9865" ||
        policy ==
        "2b4ac45ffa2c8dca38354121bb581ce582819106c48920b4bcf3a109") {
        // Extract base64 content from the data URL
        const base64Content = result.split(',')[1];
        // Decode base64 string to get HTML content
        const decodedHTML = Buffer.from(base64Content, 'base64').toString('utf-8');
        // Modify html content to remove background style if it exists
        const modifiedHTML = decodedHTML.replace('body style="background:#000"', 'body');
        // Re-encode back to base64
        const modifiedBase64 = Buffer.from(modifiedHTML, 'utf-8').toString('base64');

        setRawNFT(`data:text/html;base64,${modifiedBase64}`);
      } else {
        setRawNFT(result)
      }
    }
  }, [src]);

  React.useEffect(() => {
    const fetchAssetListingData = async () => {
      try {
        let assetListingData = await fetchAssetListing(policy, id || "");
        setAssetListingData(assetListingData);
      } catch (error) {
        console.error('Error fetching asset listing data:', error);
      }
    };

    fetchAssetListingData();
  }, [policy, id]);


  return (
    <div
      className={`nc-CardLarge1 nc-CardLarge1--hasAnimation relative flex flex-col-reverse lg:flex-row justify-end ${className}`}
    >
      <div className="lg:absolute z-10 lg:left-0 lg:top-1/2 lg:transform lg:-translate-y-1/2 -mt-2 lg:mt-0 sm:px-5 lg:px-0 w-full lg:max-w-lg ">
        <div className="nc-CardLarge1__left p-4 sm:p-8 xl:py-14 md:px-10 bg-white dark:bg-neutral-900 shadow-lg rounded-3xl space-y-3 sm:space-y-8 ">
          {/* TITLE */}
          <h2 className="text-2xl lg:text-3xl 2xl:text-5xl font-semibold ">
            <Link to={"/asset"} title="Asset">
              {name}
            </Link>
          </h2>
          {/* AUTHOR AND COLLECTION */}
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-12">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar sizeClass="w-10 h-10" />
              </div>
              <div className="ml-3">
                <div className="text-xs dark:text-neutral-400">Creator</div>
                <div className="text-sm font-semibold flex items-center">
                  <span>{collectionData?.owner}</span>
                  <VerifyIcon />
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <Avatar
                  imgUrl={collectionData?.avatarpath ? collectionData.avatarpath : ''}
                  sizeClass="h-9 w-9"
                  radius="rounded-full"
                />
              </div>
              <div className="ml-3">
                <div className="text-xs dark:text-neutral-400">Collection</div>
                <div className="text-sm font-semibold ">{collectionData?.name}</div>
              </div>
            </div>
          </div>
          {/* PRICE */}
          <div className="pt-6">
            <div className="flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative">
              <span className="block absolute bottom-full translate-y-1.5 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400 ring ring-offset-0 ring-white dark:ring-neutral-900">
                Current Price
              </span>
              <span className="text-3xl xl:text-4xl font-semibold">
                {assetListingData ? (
                  <span className="text-white">{assetListingData.price.toString()} ADA</span>
                ) : (
                  <span className="text-gray-500">Unlisted</span>
                )}
              </span>
            </div>
          </div>
          {/* AUTION TIME */}
          <div style={{ minHeight: '100px' }}>
            {collectionData?.description?.length ?? 0 > 200
              ? `${collectionData?.description?.slice(0, 200) ?? ''}...`
              : collectionData?.description}
          </div>
          <div className="w h-[1px] bg-neutral-100 dark:bg-neutral-700"></div>
          {/* DESCRIPTION */}
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
            <Link to={`/collection/${collectionData?.path}`}>
              <ButtonPrimary className="flex-1 w-full">
                View Collection
              </ButtonPrimary>
            </Link>
            <Link to={`/${collectionData?.path}/${id}`}>
              <ButtonSecondary className="flex-1 w-full">
                View Asset
              </ButtonSecondary>
            </Link>
          </div>
        </div>
        <div className="p-4 sm:pt-8 sm:px-10 ">
          <NextPrev
            btnClassName="w-11 h-11 text-xl"
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />
        </div>
      </div>

      <div className="w-full lg:w-[64%] relative ">
        <div className="nc-CardLarge1__right ">
          <Link to={`/${collectionData?.path}/${id}`}>
            {
              rawNFT.startsWith('ipfs://') || rawNFT.startsWith('data:image/') ? (

                <NcImage
                  containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0 aspect-w-12 aspect-h-12"
                  src={image}
                  className="h-full w-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
                />
              ) : (
                <div className="flex aspect-w-11 aspect-h-12 w-full h-full  overflow-hidden z-0 aspect-w-12 aspect-h-12">
                  <iframe className="h-full w-full" src={rawNFT} title="asset" />
                </div>
              )}
          </Link>

          {/* META TYPE 
          <ItemTypeVideoIcon className="absolute w-8 h-8 md:w-10 md:h-10 left-3 bottom-3 sm:left-7 sm:bottom-7 " />*/}

          {/* META FAVORITES 
          <LikeButton className="absolute right-3 top-3 sm:right-7 sm:top-7" />*/}
        </div>
      </div>
    </div>
  );
};

export default CardLarge1;
